/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
.switch[data-v-0b33f1e8] {
  --switch-width: 40px;
  --switch-height: 20px;
  --switch-thumb-size: 16px;
  --switch-background-color: #dddddd;
  --switch-on-color: rgb(233, 63, 111);
  --switch-disabled-color: #ccc;
  --switch-thumb-disabled-color: #e0e0e0;
  width: var(--switch-width);
  height: var(--switch-height);
  background-color: var(--switch-background-color);
  border: none;
  border-radius: calc(var(--switch-height) / 2);
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s;
}
.switch__thumb[data-v-0b33f1e8] {
  width: var(--switch-thumb-size);
  height: var(--switch-thumb-size);
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: calc(var(--switch-height) / 10);
  transform: translate(0, -50%);
  transition: left 0.3s, background-color 0.3s;
}
.switch--on[data-v-0b33f1e8] {
  background-color: var(--switch-on-color);
}
.switch--on .switch__thumb[data-v-0b33f1e8] {
  left: calc(100% - var(--switch-thumb-size) - var(--switch-height) / 10);
}
.switch--disabled[data-v-0b33f1e8] {
  background-color: var(--switch-disabled-color);
  cursor: not-allowed;
}
.switch--disabled .switch__thumb[data-v-0b33f1e8] {
  background-color: var(--switch-thumb-disabled-color);
}