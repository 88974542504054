/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
.confirm-delete-row[data-v-e03d3916] {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: #f2f2f2;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.effective-date[data-v-e03d3916] {
  justify-content: space-between;
  height: 4rem;
}
.effective-date .inner-content[data-v-e03d3916] {
  padding: 1rem;
}
.effective-date .inner-content.ticker-table-icon[data-v-e03d3916] {
  align-self: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.effective-date .date-text[data-v-e03d3916] {
  text-align: left;
  flex-grow: 1;
  margin-right: 2rem;
}
.confirm-delete[data-v-e03d3916] {
  padding: 1rem;
  display: flex;
  align-items: center;
  align-self: center;
  cursor: pointer;
}
.confirm-delete-text[data-v-e03d3916] {
  color: #d2222d;
  padding-left: 1rem;
}
.cursor-pointer[data-v-e03d3916] {
  cursor: pointer;
}