/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
.btn--next[data-v-8f8578fa] {
  margin-left: auto;
  background-color: #ebebeb;
  color: black;
  padding: 1rem;
}
.card[data-v-8f8578fa] {
  box-shadow: inset 0 0 5px silver;
  font-family: inherit;
  font-size: 16px;
  color: #35495e;
  cursor: pointer;
  white-space: nowrap;
  margin-bottom: 5px;
  height: 60px;
  max-height: 60px;
  overflow-y: auto;
  padding: 5px 10px;
  background: white;
  white-space: normal;
  border-radius: 5px;
}
.card[data-v-8f8578fa]:hover, .card[data-v-8f8578fa]:focus-visible {
  background: #e93f6f;
  color: white;
}
.card.selected[data-v-8f8578fa] {
  background: #002f5f;
  color: white;
}
.text-right[data-v-8f8578fa] {
  text-align: right;
}
.search-wrapper[data-v-8f8578fa] {
  position: sticky;
  top: -10px;
  background: #f2f2f2;
  border-radius: 5px;
  border-bottom: 1px solid silver;
}
.search[data-v-8f8578fa] {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  margin: 0;
  font-family: inherit;
  font-size: 16px;
  min-height: 40px;
  line-height: 40px;
  border: none;
  border-radius: 5px;
  background: #fff;
  transition: border 0.1s ease;
  box-sizing: border-box;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}
.ticker-details-wrapper[data-v-8f8578fa] {
  width: 100% !important;
  min-height: 45rem !important;
  height: 80vh !important;
  padding: 1rem 0 0;
}
.ticker-details-heading[data-v-8f8578fa] {
  height: 5rem;
  padding: 0 1rem;
  align-items: center;
}
.ticker-details-heading h2[data-v-8f8578fa] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.content-box[data-v-8f8578fa] {
  border-top: 1px solid #bfbfbf;
  border-bottom: 1px solid #bfbfbf;
  height: calc(100% - 11rem);
  display: grid;
  grid-template-columns: 1fr 1fr;
  overflow-y: scroll;
}
.details-bottom[data-v-8f8578fa] {
  justify-content: center;
  padding: 1rem 0;
}
.details-bottom .discard[data-v-8f8578fa] {
  margin-right: 2rem;
}
.details-bottom .discard.btn--default[data-v-8f8578fa] {
  background-color: #ebebeb;
  color: black;
}
.selectors-row[data-v-8f8578fa] {
  padding: 10px 15px;
  background: #f2f2f2;
  overflow-y: auto;
}
.selectors-row label[data-v-8f8578fa] {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}
.selectors-row input[data-v-8f8578fa] {
  display: block;
}
.selectors-row select[data-v-8f8578fa] {
  width: 100%;
  padding: 0.8rem;
  cursor: pointer;
  max-width: 250px;
}
.selectors-row select[data-v-8f8578fa]:hover {
  border-color: #409aff;
}
.selectors-row[data-v-8f8578fa] .formattedValue {
  left: 2px;
  right: 2px;
}
.selectors-row[data-v-8f8578fa] .vue-treeselect--open-below .vue-treeselect__menu {
  border: solid 0.5px #424242;
  border-radius: 0px;
}
.selectors-row[data-v-8f8578fa] .vue-treeselect--open.vue-treeselect--open-below .vue-treeselect__control {
  border: solid #424242 0.5px;
  border-radius: 0px;
}
.selectors-row[data-v-8f8578fa] .vue-treeselect__control {
  border: solid 0.5px #424242 !important;
  border-radius: 0px !important;
}
.ticker-info-box[data-v-8f8578fa] {
  padding: 10px 15px;
}
.ticker-info-box label[data-v-8f8578fa] {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}
.ticker-info-box .ticker-info-label[data-v-8f8578fa] {
  font-size: 12px;
  margin-bottom: 15px;
}
.disabled[data-v-8f8578fa] {
  opacity: 0.2;
}
.date-range[data-v-8f8578fa] {
  font-size: 12px;
}
.graph-row[data-v-8f8578fa] {
  padding: 20px;
}
.error-message[data-v-8f8578fa] {
  padding: 10px 15px;
  color: red;
}
.loader-container[data-v-8f8578fa] {
  font-size: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #c3c3c3;
  height: 100%;
}
.description-title[data-v-8f8578fa] {
  font-size: 2rem;
  font-weight: bolder;
}