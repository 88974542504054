/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
.ticker-details-container[data-v-4ab4d7d8] {
  width: 100%;
}
.benchmark-name-input[data-v-4ab4d7d8] {
  flex: 1;
}
.delete-text[data-v-4ab4d7d8] {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.ticker-id[data-v-4ab4d7d8] {
  border: none;
  padding: 5px;
  border: 2px solid #ccc;
  border-radius: 2px;
  text-align: right;
  width: 100%;
  min-width: -moz-max-content;
  min-width: max-content;
}
.ticker-id[data-v-4ab4d7d8]:focus {
  outline: none;
  border-color: #e93f6f;
}
.ticker-table[data-v-4ab4d7d8] {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  table-layout: auto;
}
.ticker-table th[data-v-4ab4d7d8]:not(:first-child),
.ticker-table td[data-v-4ab4d7d8]:not(:first-child) {
  border: 1px solid white;
}
.ticker-table th[data-v-4ab4d7d8] {
  padding: 0.5rem 1rem;
  font-weight: 700;
}
.ticker-table td[data-v-4ab4d7d8] {
  padding: 0.5rem 1rem;
}
.ticker-table td[data-v-4ab4d7d8] .formattedValue {
  left: 2px;
  right: 2px;
}
.ticker-details-wrapper[data-v-4ab4d7d8] {
  height: 45rem;
  padding: 1rem 0 0;
}
.ticker-details-wrapper .ticker-details-heading[data-v-4ab4d7d8] {
  height: 5rem;
  padding: 0 1rem;
  align-items: center;
}
.ticker-details-wrapper .ticker-details-heading h2[data-v-4ab4d7d8] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ticker-details-wrapper .ticker-details-heading input[type=text][data-v-4ab4d7d8] {
  padding: 3px 10px;
  font-size: 1.5em;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 5px;
  background: #f2f2f2;
  border: 1px solid #cfcfcf;
}
.ticker-details-wrapper .ticker-details-heading .btn--small[data-v-4ab4d7d8] {
  font-size: 1.2rem;
  width: auto;
  padding: 5px 10px;
  margin-left: auto;
}
.ticker-details-wrapper .ticker-details-heading .primary-label[data-v-4ab4d7d8] {
  font-size: 1.5em;
  margin: 0 0 0 auto;
}
.ticker-details-wrapper .details[data-v-4ab4d7d8] {
  border-top: 1px solid #bfbfbf;
  border-bottom: 1px solid #bfbfbf;
  height: calc(100% - 11rem);
}
.ticker-details-wrapper .details .content-heading[data-v-4ab4d7d8] {
  margin-bottom: 1rem;
  padding: 1rem;
  font-size: 1.8rem;
  font-weight: 700;
}
.ticker-details-wrapper .details[data-v-4ab4d7d8] .ticker-table-icon {
  cursor: pointer;
  display: inline-block;
}
.ticker-details-wrapper .details[data-v-4ab4d7d8] .ticker-table-icon:not(:last-of-type) {
  margin-right: 1rem;
}
.ticker-details-wrapper .details .add-new[data-v-4ab4d7d8] {
  padding: 1rem;
  cursor: pointer;
  text-decoration: underline;
  padding-right: 0;
  font-size: 1.3rem;
}
.ticker-details-wrapper .details .left-content[data-v-4ab4d7d8] {
  flex: 1 0 300px;
  border-right: 1px solid #bfbfbf;
}
.ticker-details-wrapper .details .left-content .effective-date-wrapper[data-v-4ab4d7d8] {
  position: relative;
  border-left: 2px solid transparent;
  background-color: #fdfdfd;
}
.ticker-details-wrapper .details .left-content .effective-date-wrapper .date-picker-holder[data-v-4ab4d7d8] {
  padding: 1rem;
  width: 100%;
}
.ticker-details-wrapper .details .left-content .effective-date-wrapper[data-v-4ab4d7d8]  .mx-datepicker {
  width: 90% !important;
  margin-right: 1rem;
}
.ticker-details-wrapper .details .left-content .effective-date-wrapper.active-date[data-v-4ab4d7d8] {
  background-color: #f2f2f2;
  border-left: 2px solid #e93f6f;
}
.ticker-details-wrapper .details .right-content[data-v-4ab4d7d8] {
  overflow-x: auto;
  width: 100%;
  padding: 2rem 1rem 1rem;
  background-color: #f2f2f2;
}
.ticker-details-wrapper .details .right-content .right-content-inner[data-v-4ab4d7d8] {
  height: 100%;
  flex-direction: column;
}
.ticker-details-wrapper .details .right-content .content-inner[data-v-4ab4d7d8] {
  flex-grow: 1;
}
.ticker-details-wrapper .details .right-content .content-inner .heading[data-v-4ab4d7d8] {
  margin-bottom: 2rem;
}
.ticker-details-wrapper .details .right-content .ticker-allocations-top[data-v-4ab4d7d8] {
  justify-content: space-between;
  margin-bottom: 2rem;
}
.ticker-details-wrapper .details .right-content .ticker-allocations[data-v-4ab4d7d8]:not(:last-of-type) {
  margin-bottom: 2rem;
}
.ticker-details-wrapper .details .right-content .right-content-bottom[data-v-4ab4d7d8] {
  justify-content: space-between;
  padding: 1rem 0 0;
  margin-right: 1rem;
}
.ticker-details-wrapper .details .right-content .right-content-bottom .error[data-v-4ab4d7d8] {
  padding-top: 1.2rem;
  padding-left: 1rem;
  color: red;
}
.ticker-details-wrapper .details .right-content .right-content-bottom .error span[data-v-4ab4d7d8] {
  display: block;
}
.ticker-details-wrapper .details-bottom[data-v-4ab4d7d8] {
  justify-content: center;
  padding: 1rem 0;
}
.ticker-details-wrapper .details-bottom .discard[data-v-4ab4d7d8] {
  margin-left: 1rem;
}
.ticker-details-wrapper .details-bottom .discard.btn--default[data-v-4ab4d7d8] {
  background-color: #ebebeb;
  color: black;
}
.ticker-details-wrapper .details-bottom .delete[data-v-4ab4d7d8] {
  margin-right: 1rem;
}
.ticker-details-wrapper .details-bottom .delete.btn--default[data-v-4ab4d7d8] {
  background: white;
  border: 1px solid #e93f6f;
  color: black;
}
.strategic-allocation-input-wrapper[data-v-4ab4d7d8] {
  display: flex;
  justify-content: flex-end;
}
.strategic-allocation-input-wrapper[data-v-4ab4d7d8] .formattedValue {
  left: 2px;
  right: 2px;
}
.flex-container[data-v-4ab4d7d8] {
  display: flex;
  align-items: center;
}
.look-up-button[data-v-4ab4d7d8] {
  margin-right: 10px;
  font-size: 1.2rem;
  cursor: pointer;
}
.status-icon[data-v-4ab4d7d8] {
  margin-left: 10px;
}
.status-icon--success[data-v-4ab4d7d8] {
  color: green;
}
.status-icon--error[data-v-4ab4d7d8] {
  color: red;
}
.flex--space-between[data-v-4ab4d7d8] {
  justify-content: space-between;
}
.link[data-v-4ab4d7d8] {
  cursor: pointer;
  color: #e93f6f;
  text-decoration: underline;
}
.link--text[data-v-4ab4d7d8] {
  font-style: italic;
}