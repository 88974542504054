/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
.sum-weight-btn[data-v-0cce5449] {
  display: inline-block;
  margin-left: 0.25rem;
  font-size: 1.2rem;
  width: auto;
  padding: 5px 10px;
  margin-left: auto;
  white-space: nowrap;
}