/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
[data-v-033c28e3] .p-0 {
  padding: 0;
}
.benchmark-rules[data-v-033c28e3] {
  margin-left: 1rem;
}
.primary-icon[data-v-033c28e3] {
  padding: 1rem;
  color: #e93f6f;
}
.actions[data-v-033c28e3] {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}
.benchmark-column[data-v-033c28e3] {
  position: relative;
  display: grid;
  justify-content: center;
}
.enabled[data-v-033c28e3] {
  color: #e93f6f;
}
table[data-v-033c28e3] {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;
}
th[data-v-033c28e3] {
  background-color: #ddd;
  color: #424242;
  padding: 0.5rem;
  text-wrap: balance;
}
tbody td[data-v-033c28e3] {
  padding: 0.5rem;
  vertical-align: middle;
}
tbody tr[data-v-033c28e3] {
  position: relative;
}
tbody tr.loading[data-v-033c28e3]::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #f0f0f0 25%, #f8f8f8 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading-data-v-033c28e3 1.5s infinite;
}
tbody tr[data-v-033c28e3]:hover {
  background-color: #f0f0f0;
}
@keyframes loading-data-v-033c28e3 {
0% {
    background-position: 200% 0;
}
100% {
    background-position: -200% 0;
}
}