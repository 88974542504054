/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
tfoot[data-v-45584fa2] {
  border-top: 1px solid #e6e6e6;
}
.notional-benchmark-text[data-v-45584fa2] {
  font-display: italic;
  font-size: small;
  padding-left: 0.6rem;
}
.check[data-v-45584fa2] {
  color: #e93f6f;
}
.benchmark-rules[data-v-45584fa2] {
  margin-left: 1rem;
}
.primary-icon[data-v-45584fa2] {
  padding: 1rem;
  color: #e93f6f;
}
.actions[data-v-45584fa2] {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
}
table[data-v-45584fa2] {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;
}
th[data-v-45584fa2] {
  background-color: #ddd;
  color: #424242;
  padding: 0.5rem 0.25rem;
}
th.w-20[data-v-45584fa2] {
  width: 20%;
}
th.w-40[data-v-45584fa2] {
  width: 40%;
}
tbody td[data-v-45584fa2] {
  padding: 0.25rem;
  vertical-align: baseline;
}
tbody tr[data-v-45584fa2] {
  position: relative;
}
tbody tr.loading[data-v-45584fa2]::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #f0f0f0 25%, #f8f8f8 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading-data-v-45584fa2 1.5s infinite;
}
tbody tr[data-v-45584fa2]:hover {
  background-color: #f0f0f0;
}
@keyframes loading-data-v-45584fa2 {
0% {
    background-position: 200% 0;
}
100% {
    background-position: -200% 0;
}
}