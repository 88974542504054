/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
.date-picker-holder[data-v-276c4f97] {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 4rem;
}
.date-picker-holder .times-icon[data-v-276c4f97] {
  font-size: 1.6rem !important;
}
[data-v-276c4f97] .mx-datepicker {
  width: 90% !important;
  margin-right: 1rem;
}