/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
.wrapper[data-v-03b99882] {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.wrapper .theme-icon[data-v-03b99882] {
  float: left;
}
.wrapper span[data-v-03b99882] {
  margin-left: 20px;
  line-height: 40px;
}
.wrapper:hover .theme-icon[data-v-03b99882] {
  background: #dc1950;
}
.wrapper:hover span[data-v-03b99882] {
  color: #e93f6f;
}
.wrapper.disabled[data-v-03b99882] {
  cursor: default;
}
.wrapper.disabled .theme-icon[data-v-03b99882] {
  background: #cdcdcd;
  border-color: #333;
  cursor: not-allowed;
}
.wrapper.disabled span[data-v-03b99882] {
  color: #333 !important;
}