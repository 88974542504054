/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
.control-wrapper[data-v-f208d8b4] {
  display: grid;
  grid-template-columns: 33% auto;
  height: 100%;
  position: relative;
}
.control-wrapper .fund-not-found-message[data-v-f208d8b4] {
  gap: 1rem;
  padding: 1rem;
  position: relative;
  text-align: center;
}
.control-wrapper .fund-not-found-message button[data-v-f208d8b4] {
  background-color: #003061;
  border-radius: 1rem;
  color: #ebebeb;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
}
.control-wrapper .fund-not-found-message .icon[data-v-f208d8b4] {
  color: #d2222d;
  font-size: 120px;
  margin: 2rem auto;
}
.control-wrapper .grid-item[data-v-f208d8b4] {
  overflow: auto;
  position: relative;
}
.edit-mode[data-v-f208d8b4] {
  position: relative;
}
.edit-mode #treeview[data-v-f208d8b4]::before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  cursor: not-allowed;
}
.tree[data-v-f208d8b4] {
  padding: 1rem 1rem 5rem;
  background: #f7f7f7;
  position: relative;
}
.tree[data-v-f208d8b4]  .e-treeview > .e-ul {
  padding: 0;
}
.tree[data-v-f208d8b4]  li > .e-list-text {
  color: #e93f6f;
}
.tree[data-v-f208d8b4]  div > .e-list-text {
  color: #003061;
}
.page-options[data-v-f208d8b4] {
  padding: 2rem;
}
.save-hierarchy-options[data-v-f208d8b4] {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.save-hierarchy-options p[data-v-f208d8b4] {
  margin-bottom: 20px;
  max-width: 350px;
}
.save-hierarchy .discard[data-v-f208d8b4] {
  margin-right: 10px;
}
.save-hierarchy .discard.btn--default[data-v-f208d8b4] {
  background-color: #ebebeb;
  color: black;
}
.save-hierarchy .save[data-v-f208d8b4] {
  margin-left: 10px;
}
section[data-v-f208d8b4] {
  margin-bottom: 2rem;
}
section h1[data-v-f208d8b4] {
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  text-decoration: underline;
}
section p[data-v-f208d8b4] {
  margin-bottom: 1rem;
}
section .action[data-v-f208d8b4] {
  margin-bottom: 1rem;
}
.client-setup-page[data-v-f208d8b4] {
  width: 100%;
}