/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
small[data-v-24ad25e1] {
  white-space: nowrap;
  text-align: center;
  display: block;
}