/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
.sum-weight-text[data-v-30619876] {
  margin-left: 1.6rem;
}
.buttons-wrapper[data-v-30619876] {
  display: grid;
  justify-items: start;
  gap: 0.5rem;
}
.edit[data-v-30619876]  .show-modal .selection-holder {
  width: 80% !important;
}
.edit.allocation-modal[data-v-30619876]  .show-modal .selection-holder {
  width: 45% !important;
  min-width: 700px;
  max-width: 800px;
}
.edit.w-sm[data-v-30619876]  .show-modal .selection-holder {
  width: 80% !important;
  min-width: 800px;
}
.edit[data-v-30619876]  .show-modal.entityManagerMappings .selection-holder {
  width: 50% !important;
}
.edit .edit-heading[data-v-30619876] {
  margin-bottom: 4em;
}
.edit .form[data-v-30619876] {
  display: grid;
  grid-template-columns: 150px 1fr;
  align-items: start;
  row-gap: 3rem;
  margin-bottom: 4rem;
}
.edit .form .label[data-v-30619876] {
  font-weight: bold;
}
.edit .form .dynamic-overrides[data-v-30619876] {
  margin-left: 1.5rem;
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 0.5rem;
}
.edit .form .dynamic-overrides-info[data-v-30619876] {
  font-size: 1.2rem;
  position: relative;
  top: -1rem;
  display: inline-block;
}
.edit .form .enabled[data-v-30619876] {
  color: #e93f6f;
}
.edit .form .edit-btn[data-v-30619876] {
  color: #e93f6f;
  text-decoration: underline;
  margin-left: 1rem;
  text-align: left;
}
.edit .form .edit-btn + .add[data-v-30619876] {
  margin-top: 1rem;
}
.edit .form .benchmark-btn[data-v-30619876]::last-child {
  margin-bottom: 1rem;
}
.edit .form .benchmark-btn[data-v-30619876]::last-of-type {
  margin-bottom: 1rem;
}
.edit .form .error[data-v-30619876] {
  border-color: #e93f6f;
}
.edit .form input[data-v-30619876] {
  padding: 1rem;
  max-width: 300px;
  border: 1px solid #ccc;
  outline: none;
  border-radius: 5px 0 0 5px;
}
.edit .form input[data-v-30619876]:focus {
  border-color: #e93f6f;
}
.edit .form .input-control[data-v-30619876] {
  padding: 1rem 1rem;
  font-size: 1.2rem;
  font-weight: 400;
  color: white;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
}
.edit .form .input-control.confirm[data-v-30619876] {
  background-color: #e93f6f;
}
.edit .form .input-control.cancel[data-v-30619876] {
  background-color: #999999;
  border-radius: 0 5px 5px 0;
}
.edit .confirmation[data-v-30619876] {
  justify-content: flex-end;
  padding: 1rem 0 0;
  margin-right: 1rem;
}
.edit .confirmation .discard[data-v-30619876] {
  margin-right: 2rem;
}
.edit .confirmation .discard.btn--default[data-v-30619876] {
  background-color: #ebebeb;
  color: black;
}
.sum-weight-btn[data-v-30619876] {
  display: inline-block;
  margin-left: 0.25rem;
  font-size: 1.2rem;
  width: auto;
  padding: 5px 10px;
  margin-left: auto;
  white-space: nowrap;
}
.manager-research-link[data-v-30619876] {
  margin-left: 1rem;
  text-decoration: underline;
  -webkit-text-decoration-style: dotted;
          text-decoration-style: dotted;
}
.manager-research-link[data-v-30619876]:hover, .manager-research-link[data-v-30619876]:focus {
  color: #e93f6f;
  -webkit-text-decoration-style: solid;
          text-decoration-style: solid;
}
.label--manager[data-v-30619876] {
  align-self: flex-start;
}
.research-view-text[data-v-30619876] {
  margin-left: 1rem;
}