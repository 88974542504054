/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
.divider[data-v-fdbbbc1e] {
  padding: 1rem 2rem;
  position: relative;
  margin-bottom: 2rem;
}
.divider .divider-inner[data-v-fdbbbc1e] {
  position: relative;
  height: 1px;
  background: #999999;
  width: 80%;
  margin: 0 auto;
}